<template>
  <div style="text-align: right">
    <v-card>
      <v-card-title>
        کاربران
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="UserQualifyList" :search="search">
        <template v-slot:item.operations="{ item }">
          <div style="display:flex;justify-content:space-around">
            <router-link :to="'/documentUserManage/' + item.username">
              <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">پرونده</v-btn> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" v-on="on">
                    <v-icon large>
                      mdi-folder-open
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">پرونده</span>
              </v-tooltip>
            </router-link>
            <router-link :to="'/turnOverUser/' + item.username">
              <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">سوابق مالی</v-btn> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-clipboard-text-clock-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">سوابق مالی</span>
              </v-tooltip>
            </router-link>
            <router-link to="/securityUser">
              <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">امنیت</v-btn> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-shield-account-outline
                    </v-icon>
                  </v-btn>

                </template>
                <span style="color:white">امنیت</span>
              </v-tooltip>
            </router-link>
            <router-link to="/userMessages">
              <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">پیام ها</v-btn> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-message-settings-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">پیام ها</span>
              </v-tooltip>
            </router-link>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";
export default {
  name: "FilterUsersList",
  data() {
    return {
      UserQualifyList: [],
      headers: [
        { text: 'نام ', value: 'firstName' },
        { text: 'فامیل ', value: 'lastName' },
        { text: 'کد ملی ', value: 'nationalId' },
        { text: 'تلفن همراه', value: 'username' },
        { text: 'عملیات', value: 'operations' },
      ],
      search: ''
    };
  }
  ,
  mounted() {
    this.getUserQualifyList();
  },
  methods: {
    getUserQualifyList() {
      axiosApi().post('/api/Manage/v1/User/GetList', {
        qualifyLevelId: this.$root.choosedLevelId
      }).then(({ data }) => {
        this.UserQualifyList = data.data;
      });
    },
  }
};
</script>

<style scoped>

</style>